import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  OnInit,
  AfterViewInit,
} from "@angular/core";
import { UsuarioBuilder } from "src/app/builder/usuario.builder";
import { AppRouteChangeService } from "src/app/core/services/app-route-change.service";
import { LoginService } from "src/app/login/service/login.service";
import { Usuario } from "src/app/model/usuario.model";
import { RouterManagerService } from "./routermanager.service";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { Router } from "@angular/router";
import { TermoTratamentoDados } from "src/app/model/termotratamentodados.model";
import { TermoUso } from "src/app/model/termouso.model";
import { UsuarioService } from "../component/usuario/service/usuario.service";
import { AceiteTermoService } from "../component/aceitetermouso/service/aceitetermouso.service";
import { AceiteTermoTratamentoDadosService } from "../component/aceitetermotratamentodados/service/aceitetermotratamentodados.service";
import { CadastroPublicoLocalService } from "../component/cadastropublico/service/cadastro.publico.local.service";
import { LoggedInUserSharedDataService } from "src/app/service/logged-in-user-shared-data.service";
import { PessoaService } from "../component/pessoa/service/pessoa.service";
import { FormadorComunitario } from "src/app/model/formadorcomunitario.model";
import { FormadorComunitarioService } from "../service/formador.comunitario.service";
import { Pessoa } from "src/app/model/pessoa.model";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";

@Component({
  selector: "secured",
  templateUrl: "./secured.component.html",
})
export class SecuredComponent implements OnInit, AfterViewInit {
  @ViewChild("acessoNegado", { static: true })
  menuAcessoNegadoRef: ElementRef;
  @ViewChild("missionario", { static: true })
  menuMissionarioRef: ElementRef;
  @ViewChild("comissaoDiscernimento", { static: true })
  menuComissaoDiscernimentoRef: ElementRef;
  @ViewChild("coordenadorColegiado", { static: true })
  menuCoordenadorColegiadoRef: ElementRef;
  @ViewChild("conselho", { static: true })
  menuConselhoRef: ElementRef;
  @ViewChild("formadorComunitario", { static: true })
  menuFormadorComunitarioRef: ElementRef;
  @ViewChild("formadorComunitarioCasa", { static: true })
  menuFormadorComunitarioCasaRef: ElementRef;
  @ViewChild("formadorAssistente", { static: true })
  menuFormadorAssistenteRef: ElementRef;

  public usuarioAtual: Usuario;
  private usuarioBuilder: UsuarioBuilder;
  public usuarioAceitouTermoUso: boolean;
  public usuarioAceitouTermoTratamentoDados: boolean;
  public hasTermoUsoVigente: boolean;
  public hasTermoTratamentoDadosVigente: boolean;
  public termoUsoVigente: TermoUso;
  public termoTratamentoDadosVigente: TermoTratamentoDados;
  public pessoaAtual: Pessoa;
  public pessoaBuilder: PessoaBuilder;
  public formadorComunitarioCelula: FormadorComunitario;
  public formadorComunitarioCasa: FormadorComunitario;

  constructor(
    public loginService: LoginService,
    public usuarioService: UsuarioService,
    private pessoaService: PessoaService,
    private loggedInUserSharedDataService: LoggedInUserSharedDataService,
    public routerManagerService: RouterManagerService,
    public appRouteChangeService: AppRouteChangeService, //necessário para rodar as mudanças de rota na aplicação
    public aceiteTermoService: AceiteTermoService,
    public aceiteTermoTratamentoDadosService: AceiteTermoTratamentoDadosService,
    public cadastroPublicoService: CadastroPublicoLocalService,
    public formadorComunitarioService: FormadorComunitarioService,
    public swtAlert2Service: SwtAlert2Service,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.usuarioBuilder = new UsuarioBuilder();
    this.pessoaBuilder = new PessoaBuilder();
    this.usuarioAtual = this.usuarioBuilder.getInstance();
    this.pessoaAtual = this.pessoaBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initialization();
  }

  ngAfterViewInit(): void {
    this.initializationAfterRender();
  }

  async initialization(): Promise<any> {
    this.loadTermsInfo();

    if (this.hasTermoUsoVigente == undefined) {
      await this.carregarTermoUsoVigente();
    }

    if (this.hasTermoTratamentoDadosVigente == undefined) {
      await this.carregarTermoTratamentoDadosVigente();
    }

    if (this.hasTermoUsoVigente && this.usuarioAceitouTermoUso != true) {
      await this.didUserAcceptUserTerm();
    }

    if (
      this.hasTermoTratamentoDadosVigente &&
      this.usuarioAceitouTermoTratamentoDados != true
    ) {
      await this.didUserAcceptDataProcessingTerm();
    }
  }

  async initializationAfterRender(): Promise<any> {
    await this.getUsuarioAtual();
    if (this.usuarioAtual.id) {
      await this.getPessoaAtualPorUsuarioId();
      await this.verificarUsuario();
      await this.liberarMenuPorAutorizacao();
    }
  }

  async liberarMenuPorAutorizacao(): Promise<any> {
    let autorizacaoUsuarioLogado =
      this.usuarioAtual.autorizacoesNome.length > 0
        ? this.usuarioAtual.autorizacoesNome[0]
        : undefined;

    switch (autorizacaoUsuarioLogado) {
      case "ROLE_MISSIONARIO": {
        this.renderer.removeChild(
          this.menuComissaoDiscernimentoRef.nativeElement.parentNode,
          this.menuComissaoDiscernimentoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuCoordenadorColegiadoRef.nativeElement.parentNode,
          this.menuCoordenadorColegiadoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuConselhoRef.nativeElement.parentNode,
          this.menuConselhoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioRef.nativeElement.parentNode,
          this.menuFormadorComunitarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioCasaRef.nativeElement.parentNode,
          this.menuFormadorComunitarioCasaRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorAssistenteRef.nativeElement.parentNode,
          this.menuFormadorAssistenteRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuAcessoNegadoRef.nativeElement.parentNode,
          this.menuAcessoNegadoRef.nativeElement
        );
        break;
      }
      case "ROLE_FORMADOR_ASSISTENTE": {
        this.renderer.removeChild(
          this.menuComissaoDiscernimentoRef.nativeElement.parentNode,
          this.menuComissaoDiscernimentoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuCoordenadorColegiadoRef.nativeElement.parentNode,
          this.menuCoordenadorColegiadoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuConselhoRef.nativeElement.parentNode,
          this.menuConselhoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioRef.nativeElement.parentNode,
          this.menuFormadorComunitarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioCasaRef.nativeElement.parentNode,
          this.menuFormadorComunitarioCasaRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuMissionarioRef.nativeElement.parentNode,
          this.menuMissionarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuAcessoNegadoRef.nativeElement.parentNode,
          this.menuAcessoNegadoRef.nativeElement
        );
        break;
      }
      case "ROLE_FORMADOR_COMUNITARIO": {
        this.carregarFormadorComunitario();
        this.renderer.removeChild(
          this.menuComissaoDiscernimentoRef.nativeElement.parentNode,
          this.menuComissaoDiscernimentoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorAssistenteRef.nativeElement.parentNode,
          this.menuFormadorAssistenteRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuCoordenadorColegiadoRef.nativeElement.parentNode,
          this.menuCoordenadorColegiadoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuConselhoRef.nativeElement.parentNode,
          this.menuConselhoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuMissionarioRef.nativeElement.parentNode,
          this.menuMissionarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuAcessoNegadoRef.nativeElement.parentNode,
          this.menuAcessoNegadoRef.nativeElement
        );
        break;
      }
      case "ROLE_COMISSAO_DISCERNIMENTO": {
        this.renderer.removeChild(
          this.menuCoordenadorColegiadoRef.nativeElement.parentNode,
          this.menuCoordenadorColegiadoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuConselhoRef.nativeElement.parentNode,
          this.menuConselhoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorAssistenteRef.nativeElement.parentNode,
          this.menuFormadorAssistenteRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioRef.nativeElement.parentNode,
          this.menuFormadorComunitarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioCasaRef.nativeElement.parentNode,
          this.menuFormadorComunitarioCasaRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuMissionarioRef.nativeElement.parentNode,
          this.menuMissionarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuAcessoNegadoRef.nativeElement.parentNode,
          this.menuAcessoNegadoRef.nativeElement
        );
        break;
      }
      case "ROLE_COORDENADOR_COLEGIADO": {
        this.renderer.removeChild(
          this.menuComissaoDiscernimentoRef.nativeElement.parentNode,
          this.menuComissaoDiscernimentoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuConselhoRef.nativeElement.parentNode,
          this.menuConselhoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorAssistenteRef.nativeElement.parentNode,
          this.menuFormadorAssistenteRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioRef.nativeElement.parentNode,
          this.menuFormadorComunitarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioCasaRef.nativeElement.parentNode,
          this.menuFormadorComunitarioCasaRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuMissionarioRef.nativeElement.parentNode,
          this.menuMissionarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuAcessoNegadoRef.nativeElement.parentNode,
          this.menuAcessoNegadoRef.nativeElement
        );
        break;
      }
      case "ROLE_CONSELHO": {
        this.renderer.removeChild(
          this.menuComissaoDiscernimentoRef.nativeElement.parentNode,
          this.menuComissaoDiscernimentoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuCoordenadorColegiadoRef.nativeElement.parentNode,
          this.menuCoordenadorColegiadoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorAssistenteRef.nativeElement.parentNode,
          this.menuFormadorAssistenteRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioRef.nativeElement.parentNode,
          this.menuFormadorComunitarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioCasaRef.nativeElement.parentNode,
          this.menuFormadorComunitarioCasaRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuMissionarioRef.nativeElement.parentNode,
          this.menuMissionarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuAcessoNegadoRef.nativeElement.parentNode,
          this.menuAcessoNegadoRef.nativeElement
        );
        break;
      }
      case "ROLE_ADMIN": {
        this.renderer.removeChild(
          this.menuComissaoDiscernimentoRef.nativeElement.parentNode,
          this.menuComissaoDiscernimentoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuConselhoRef.nativeElement.parentNode,
          this.menuConselhoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorAssistenteRef.nativeElement.parentNode,
          this.menuFormadorAssistenteRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioRef.nativeElement.parentNode,
          this.menuFormadorComunitarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioCasaRef.nativeElement.parentNode,
          this.menuFormadorComunitarioCasaRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuMissionarioRef.nativeElement.parentNode,
          this.menuMissionarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuAcessoNegadoRef.nativeElement.parentNode,
          this.menuAcessoNegadoRef.nativeElement
        );
        break;
      }
      default: {
        this.renderer.removeChild(
          this.menuComissaoDiscernimentoRef.nativeElement.parentNode,
          this.menuComissaoDiscernimentoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuCoordenadorColegiadoRef.nativeElement.parentNode,
          this.menuCoordenadorColegiadoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuConselhoRef.nativeElement.parentNode,
          this.menuConselhoRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioRef.nativeElement.parentNode,
          this.menuFormadorComunitarioRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorComunitarioCasaRef.nativeElement.parentNode,
          this.menuFormadorComunitarioCasaRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuFormadorAssistenteRef.nativeElement.parentNode,
          this.menuFormadorAssistenteRef.nativeElement
        );
        this.renderer.removeChild(
          this.menuMissionarioRef.nativeElement.parentNode,
          this.menuMissionarioRef.nativeElement
        );
      }
    }
  }

  async carregarTermoUsoVigente(): Promise<any> {
    try {
      let response = await this.cadastroPublicoService.getTermoVigente();
      this.termoUsoVigente = response.entity;
      this.hasTermoUsoVigente = this.termoUsoVigente ? true : false;
      sessionStorage.setItem(
        "hasTermoUsoVigente",
        this.hasTermoUsoVigente ? "1" : "0"
      );
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarTermoTratamentoDadosVigente(): Promise<any> {
    try {
      let response =
        await this.cadastroPublicoService.getTermoTratamentoDadosVigente();
      this.termoTratamentoDadosVigente = response.entity;
      this.hasTermoTratamentoDadosVigente = this.termoTratamentoDadosVigente
        ? true
        : false;
      sessionStorage.setItem(
        "hasTermoTratamentoDadosVigente",
        this.hasTermoTratamentoDadosVigente ? "1" : "0"
      );
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async getUsuarioAtual(): Promise<any> {
    try {
      let response2 = await this.usuarioService.getCurrentUser();
      this.usuarioAtual = response2.entity;
      this.loggedInUserSharedDataService.updateLoggedInUser(this.usuarioAtual);
    } catch (e) {
      if (e.status > 400 && e.status < 500) {
        this.swtAlert2Service.errorAlert(
          "Seu usuário não tem autorização para acessar esse módulo."
        );
      } else {
        this.swtAlert2Service.errorAlert(
          "Não foi possível realizar o login. O sistema pode estar em manutenção, caso o problema persista tente realizar o login novamente mais tarde!"
        );
      }

      this.router.navigate(["/login"]);
    }
  }

  async getPessoaAtualPorUsuarioId(): Promise<any> {
    const response = await this.pessoaService.buscaPorUsuarioId(
      this.usuarioAtual.id
    );
    this.pessoaAtual = response.entity;

    if (
      JSON.parse(sessionStorage.getItem("pessoaAtual")) == undefined ||
      JSON.parse(sessionStorage.getItem("pessoaAtual")) == null
    ) {
      sessionStorage.setItem("pessoaAtual", JSON.stringify(this.pessoaAtual));
    }

    this.loggedInUserSharedDataService.updateLoggedInPerson(response.entity);
  }

  async didUserAcceptUserTerm(): Promise<any> {
    try {
      let response = await this.aceiteTermoService.usuarioAceitouTermo();
      this.usuarioAceitouTermoUso = response.entity;

      sessionStorage.setItem(
        "usuarioAceitouTermoUso",
        this.usuarioAceitouTermoUso ? "1" : "0"
      );

      if (!this.usuarioAceitouTermoUso) {
        this.router.navigate(["/aceitetermouso"]);
      }
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
      this.router.navigate(["/login"]);
    }
  }

  async didUserAcceptDataProcessingTerm(): Promise<any> {
    try {
      let response =
        await this.aceiteTermoTratamentoDadosService.usuarioAceitouTermo();
      this.usuarioAceitouTermoTratamentoDados = response.entity;
      sessionStorage.setItem(
        "usuarioAceitouTermoTratamentoDados",
        this.usuarioAceitouTermoTratamentoDados ? "1" : "0"
      );
      if (!this.usuarioAceitouTermoTratamentoDados) {
        this.router.navigate(["/aceitetermotratamentodados"]);
      }
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
      this.router.navigate(["/login"]);
    }
  }

  async verificarUsuario(): Promise<any> {
    await this.routerManagerService.verificarUsuario(this.usuarioAtual);
  }

  loadTermsInfo(): void {
    if (sessionStorage.getItem("usuarioAceitouTermoUso") != null)
      this.usuarioAceitouTermoUso =
        sessionStorage.getItem("usuarioAceitouTermoUso").localeCompare("1") == 0
          ? true
          : false;
    if (sessionStorage.getItem("usuarioAceitouTermoTratamentoDados") != null)
      this.usuarioAceitouTermoTratamentoDados =
        sessionStorage
          .getItem("usuarioAceitouTermoTratamentoDados")
          .localeCompare("1") == 0
          ? true
          : false;
    if (sessionStorage.getItem("hasTermoUsoVigente") != null)
      this.hasTermoUsoVigente =
        sessionStorage.getItem("hasTermoUsoVigente").localeCompare("1") == 0
          ? true
          : false;
    if (sessionStorage.getItem("hasTermoTratamentoDadosVigente") != null)
      this.hasTermoTratamentoDadosVigente =
        sessionStorage
          .getItem("hasTermoTratamentoDadosVigente")
          .localeCompare("1") == 0
          ? true
          : false;
  }

  async carregarFormadorComunitario(): Promise<any> {
    try {
      let responseCelula =
        await this.formadorComunitarioService.buscarCelulaPorPessoaId(
          this.pessoaAtual.id
        );
      this.formadorComunitarioCelula = responseCelula;
      let responseCasa =
        await this.formadorComunitarioService.buscarCasaComunitariaPorPessoaId(
          this.pessoaAtual.id
        );
      this.formadorComunitarioCasa = responseCasa;

      if (
        this.formadorComunitarioCelula != undefined &&
        this.formadorComunitarioCelula != null
      ) {
        this.renderer.removeChild(
          this.menuFormadorComunitarioCasaRef.nativeElement.parentNode,
          this.menuFormadorComunitarioCasaRef.nativeElement
        );
      } else if (
        this.formadorComunitarioCasa != undefined &&
        this.formadorComunitarioCasa != null
      ) {
        this.renderer.removeChild(
          this.menuFormadorComunitarioRef.nativeElement.parentNode,
          this.menuFormadorComunitarioRef.nativeElement
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
}

import { GenericService } from "src/app/core/services/generic.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RespostaParecer } from "src/app/model/respostaparecer.model";

@Injectable()
export class RespostaParecerService extends GenericService<RespostaParecer> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType("respostaparecer");
  }

  porPessoaId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}parecer_resposta_responsavel/porpessoaid/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  porPessoaIdEParecerId(id: number, parecerId: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}parecer_resposta_responsavel/porpessoaid/${id}/p/${parecerId}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }  

  buscaPublicaPorPessoaIdEParecerId(id: number, parecerId: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}parecer_resposta_responsavel/pb/porpessoaid/${id}/p/${parecerId}`)
      .toPromise();
  }  

  cadastrar(request: any) {
    return this.http
      .post<any>(
        `${environment.moduloFormacao.urlModuloFormacao}parecer_resposta_responsavel`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  atualizar(id: number, request: any) {
    return this.http
      .put<any>(
        `${environment.moduloFormacao.urlModuloFormacao}parecer_resposta_responsavel/${id}`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }
}

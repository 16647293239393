import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
export class ParecerCALAnswerDefaultViewPublicComponent {
    constructor(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFormandoCAService, caminhoFormativoService, respostaParecerService, questionarioFormandoService, parecerResponsavelService, dadoFormandoCABuilder, caminhoFormativoFormandoBuilder, respostaParecerBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFormandoCAService = dadoFormandoCAService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.respostaParecerService = respostaParecerService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelService = parecerResponsavelService;
        this.dadoFormandoCABuilder = dadoFormandoCABuilder;
        this.caminhoFormativoFormandoBuilder = caminhoFormativoFormandoBuilder;
        this.respostaParecerBuilder = respostaParecerBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.dadoFormandoCA = this.dadoFormandoCABuilder.getInstance();
        this.caminhoFormativoFormando =
            this.caminhoFormativoFormandoBuilder.getInstance();
        this.imageChangedEvent = "";
        this.croppedImage = "";
        this.nextClicked = false;
        this.pessoaResponsavel = this.pessoaBuilder.getInstance();
        this.formando = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.resposta36 = respostaParecerBuilder.getInstance();
        this.resposta37 = respostaParecerBuilder.getInstance();
        this.resposta38 = respostaParecerBuilder.getInstance();
        this.resposta39 = respostaParecerBuilder.getInstance();
        this.resposta40 = respostaParecerBuilder.getInstance();
        this.resposta41 = respostaParecerBuilder.getInstance();
        this.resposta42 = respostaParecerBuilder.getInstance();
        this.resposta43 = respostaParecerBuilder.getInstance();
        this.resposta44 = respostaParecerBuilder.getInstance();
        this.resposta45 = respostaParecerBuilder.getInstance();
        this.resposta46 = respostaParecerBuilder.getInstance();
        this.resposta47 = respostaParecerBuilder.getInstance();
        this.resposta48 = respostaParecerBuilder.getInstance();
        this.resposta49 = respostaParecerBuilder.getInstance();
        this.resposta50 = respostaParecerBuilder.getInstance();
        this.resposta51 = respostaParecerBuilder.getInstance();
        this.resposta52 = respostaParecerBuilder.getInstance();
        this.resposta53 = respostaParecerBuilder.getInstance();
        this.resposta54 = respostaParecerBuilder.getInstance();
        this.resposta55 = respostaParecerBuilder.getInstance();
        this.resposta56 = respostaParecerBuilder.getInstance();
        this.resposta57 = respostaParecerBuilder.getInstance();
        this.resposta58 = respostaParecerBuilder.getInstance();
        this.resposta59 = respostaParecerBuilder.getInstance();
        this.resposta60 = respostaParecerBuilder.getInstance();
        this.resposta61 = respostaParecerBuilder.getInstance();
        this.resposta62 = respostaParecerBuilder.getInstance();
        this.resposta63 = respostaParecerBuilder.getInstance();
        this.resposta64 = respostaParecerBuilder.getInstance();
        this.resposta65 = respostaParecerBuilder.getInstance();
        this.resposta66 = respostaParecerBuilder.getInstance();
        this.resposta67 = respostaParecerBuilder.getInstance();
        this.resposta68 = respostaParecerBuilder.getInstance();
        this.resposta69 = respostaParecerBuilder.getInstance();
        this.resposta70 = respostaParecerBuilder.getInstance();
        this.resposta71 = respostaParecerBuilder.getInstance();
        this.resposta72 = respostaParecerBuilder.getInstance();
        this.resposta73 = respostaParecerBuilder.getInstance();
        this.resposta74 = respostaParecerBuilder.getInstance();
        this.resposta75 = respostaParecerBuilder.getInstance();
        this.resposta76 = respostaParecerBuilder.getInstance();
        this.resposta77 = respostaParecerBuilder.getInstance();
    }
    ngOnInit() {
        this.initialization();
    }
    imprimirValorDoForm() {
        console.log("Valor do Form", JSON.stringify(this.dadosPessoaisForm.value));
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //await this.carregarMissoesVinculo();
            this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get("id"));
            yield this.carregarParecerResponsavel();
            yield this.carregarQuestionarioFormando();
            /*await this.carregarPessoaFormando(this.questionarioFormando.pessoaId);
            await this.carregarDadoVocacional();
            await this.carregarDadoFormandoCA();
            await this.carregarCaminhoFormativo();*/
            yield this.carregarRespostas();
            // this.imprimirValorDoForm();
        });
    }
    carregarMissoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllPublic.getAllMissao();
                this.missoesVinculo = response.entity;
                this.missoesVinculo = this.missoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarPessoaFormando(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorId(pessoaId);
                this.formando = response.entity;
                this.pessoaFormandoId = this.formando.id;
                if (this.formando.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.formando.imagemId);
                    this.imagemService.createImage(blogImage, this.formando);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarPessoaResponsavel(usuarioId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(usuarioId);
                return response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    logOnConsole(dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    }
    activateTab(tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    }
    activateTab2(action) {
        if (action.localeCompare("previous") == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare("next") == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass("disabled");
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab("show");
    }
    fileChangeEvent(event) {
        this.imageChangedEvent = event;
        console.log(event);
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        console.log("Imagem carregada");
    }
    cropperReady() {
        console.log("Imagem cortada");
    }
    loadImageFailed() {
        console.log("Carregamento da imagem falhou!");
    }
    carregarDadoVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoVocacionalService.porPessoaId(this.formando.id);
                if (response.entity != undefined && response.entity != null) {
                    this.dadoVocacional = response.entity;
                    this.pessoaFormadorPessoalSelecionado =
                        this.pessoaInfoBuilder.getInstance();
                    this.pessoaFormadorPessoalSelecionado.nome =
                        this.dadoVocacional.formadorPessoalPessoaNome;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    selecionarFormadorComunitario(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
            //this.pessoaFormadorComunitarioSelecionado = pessoa;
            //$('#listagem-pessoa-formador-comunitario').modal('hide');
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarDadoFormandoCA() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoFormandoCAService.porPessoaId(this.formando.id);
                if (response != null && response != undefined) {
                    this.dadoFormandoCA = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.caminhoFormativoService.porPessoaId(this.formando.id);
                if (response != null && response != undefined) {
                    this.caminhoFormativoFormando = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.caminhoFormativoFormando.id != null) {
                    let response = yield this.caminhoFormativoService.atualizar(this.caminhoFormativoFormando);
                    this.caminhoFormativoFormando = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRespostas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.respostaParecerService.buscaPublicaPorPessoaIdEParecerId(this.questionarioFormando.pessoaId, this.parecerResponsavel.parecer_id);
                if (response != null && response != undefined) {
                    this.respostas = response;
                    console.log(this.respostas);
                    this.respostas.forEach((resposta) => {
                        switch (resposta.parecer_pergunta_id) {
                            case 36:
                                this.resposta36 = resposta;
                                break;
                            case 37:
                                this.resposta37 = resposta;
                                break;
                            case 38:
                                this.resposta38 = resposta;
                                break;
                            case 39:
                                this.resposta39 = resposta;
                                break;
                            case 40:
                                this.resposta40 = resposta;
                                break;
                            case 41:
                                this.resposta41 = resposta;
                                break;
                            case 42:
                                this.resposta42 = resposta;
                                break;
                            case 43:
                                this.resposta43 = resposta;
                                break;
                            case 44:
                                this.resposta44 = resposta;
                                break;
                            case 45:
                                this.resposta45 = resposta;
                                break;
                            case 46:
                                this.resposta46 = resposta;
                                break;
                            case 47:
                                this.resposta47 = resposta;
                                break;
                            case 48:
                                this.resposta48 = resposta;
                                break;
                            case 49:
                                this.resposta49 = resposta;
                                break;
                            case 50:
                                this.resposta50 = resposta;
                                break;
                            case 51:
                                this.resposta51 = resposta;
                                break;
                            case 52:
                                this.resposta52 = resposta;
                                break;
                            case 53:
                                this.resposta53 = resposta;
                                break;
                            case 54:
                                this.resposta54 = resposta;
                                break;
                            case 55:
                                this.resposta55 = resposta;
                                break;
                            case 56:
                                this.resposta56 = resposta;
                                break;
                            case 57:
                                this.resposta57 = resposta;
                                break;
                            case 58:
                                this.resposta58 = resposta;
                                break;
                            case 59:
                                this.resposta59 = resposta;
                                break;
                            case 60:
                                this.resposta60 = resposta;
                                break;
                            case 61:
                                this.resposta61 = resposta;
                                break;
                            case 62:
                                this.resposta62 = resposta;
                                break;
                            case 63:
                                this.resposta63 = resposta;
                                break;
                            case 64:
                                this.resposta64 = resposta;
                                break;
                            case 65:
                                this.resposta65 = resposta;
                                break;
                            case 66:
                                this.resposta66 = resposta;
                                break;
                            case 67:
                                this.resposta67 = resposta;
                                break;
                            case 68:
                                this.resposta68 = resposta;
                                break;
                            case 69:
                                this.resposta69 = resposta;
                                break;
                            case 70:
                                this.resposta70 = resposta;
                                break;
                            case 71:
                                this.resposta71 = resposta;
                                break;
                            case 72:
                                this.resposta72 = resposta;
                                break;
                            case 73:
                                this.resposta73 = resposta;
                                break;
                            case 74:
                                this.resposta74 = resposta;
                                break;
                            case 75:
                                this.resposta75 = resposta;
                                break;
                            case 76:
                                this.resposta76 = resposta;
                                break;
                            case 77:
                                this.resposta77 = resposta;
                                break;
                        }
                    });
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarParecerResponsavel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.parecerResponsavelService.buscaPublicaPorId(this.parecerResponsavelId);
                if (response != null && response != undefined) {
                    this.parecerResponsavel = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarQuestionarioFormando() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscaPublicaPorId(this.parecerResponsavel.questionarioFormandoId);
                if (response != null && response != undefined) {
                    this.questionarioFormando = response;
                    switch (this.questionarioFormando.questionarioId) {
                        case 4:
                            this.questionarioNome = 'Pedido para as Primeiras Promessas - CA';
                            break;
                        case 5:
                            this.questionarioNome = 'Pedido para as Promessas Definitivas - CA';
                            break;
                        case 6:
                            this.questionarioNome = 'Postulantes/Ingresso no Discipulado - CA';
                            break;
                        case 7:
                            this.questionarioNome = 'Discipulos de Primeiro Ano - CA';
                            break;
                        case 8:
                            this.questionarioNome = 'Renovação das Promessas - CA';
                            break;
                        default:
                            this.questionarioNome = null;
                            break;
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    respostaVazia(resposta) {
        return (resposta.resposta == null ||
            resposta.resposta == undefined ||
            resposta.resposta == "");
    }
}

import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { MissaoConfiguracaoService, } from "../service/configuracao-questionario.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
export class ParecerFormadorCelulaComponent {
    constructor(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, elementoGrupoDominioService, formadorAssistenteService, formadorComunitarioService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, caminhoFormativoService, arquivoService, sanitizer, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.formadorAssistenteService = formadorAssistenteService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.unsubscribe$ = new Subject();
        this.totalDePaginas = 1;
        this.idsPPs = [25];
        this.idsPDs = [30, 31, 108, 204, 205];
        this.idsD1s = [24];
        this.idsPostulantes = [22, 23];
        this.idsRenovacao = [26, 27, 28, 29, 30, 31, 108, 204];
        this.selectedFileCarta = null;
        this.selectedFileArquivo = null;
        this.errorMessage = "";
        this.isFormValidUpload = false;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
        this.hasCelulaAssociada = false;
        this.buscaMembros = {
            celulaId: 0,
            pageNumber: 0,
            quantityOfElements: 0,
        };
        this.buscaQuestionarioFormando = {
            questionarioId: null,
            casaCelulaId: null,
            pessoaId: null,
            pageNumber: 0,
            quantityOfElements: 10,
        };
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.questionarioId = Number(this.route.snapshot.paramMap.get("id"));
            this.carregarNivelFormacao();
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            yield this.carregarFormadorComunitario();
            if (this.formadorComunitario != undefined &&
                this.formadorComunitario.casaCelulaId != null) {
                yield this.carregarCelula(this.formadorComunitario.casaCelulaId);
                this.buscaMembros = {
                    celulaId: this.celula.id,
                    pageNumber: 0,
                    quantityOfElements: 4,
                };
                this.buscaQuestionarioFormando = {
                    questionarioId: this.questionarioId,
                    casaCelulaId: this.celula.id,
                    pessoaId: null,
                };
                yield this.carregarMembrosCelula();
            }
            else {
                yield this.carregarFormadorAssistente();
                if (this.formadorAssistente != undefined &&
                    this.formadorAssistente.casaCelulaId != null) {
                    yield this.carregarCelula(this.formadorAssistente.casaCelulaId);
                    this.buscaMembros = {
                        celulaId: this.celula.id,
                        pageNumber: 0,
                        quantityOfElements: 4,
                    };
                    this.buscaQuestionarioFormando = {
                        questionarioId: this.questionarioId,
                        casaCelulaId: this.celula.id,
                        pessoaId: null,
                    };
                    yield this.carregarMembrosCelula();
                }
                else
                    this.hasCelulaAssociada = false;
            }
        });
    }
    /*carregarQuestionarioConfiguracao(questionarioId: number) {
      const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
      this.missaoConfiguracaoService
        .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
          missaoId,
          questionarioId
        )
        .subscribe(
          (questionarioConfiguracao) =>
            (this.questionarioConfiguracao = questionarioConfiguracao)
        );
    }*/
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarFormadorComunitario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.formadorComunitarioService.buscarCelulaPorPessoaId(this.pessoa.id);
                this.formadorComunitario = response;
                if (this.formadorComunitario &&
                    this.formadorComunitario.pessoaImagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.formadorComunitario.pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.formadorComunitario);
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarFormadorAssistente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.formadorAssistenteService.buscarCelulaPorPessoaId(this.pessoa.id);
                this.formadorAssistente = response;
                if (this.formadorAssistente &&
                    this.formadorAssistente.pessoaImagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.formadorAssistente.pessoaImagemId);
                    this.imagemService.createImage(blogImage, this.formadorAssistente);
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarNivelFormacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: "NIVEL_FORMACAO",
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.niveisFormacao = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCelula(celulaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celulaService.find(celulaId);
                this.celula = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMembrosCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.membroService.listarTodosMembrosCelula(this.celula.id, this.buscaMembros.pageNumber);
                this.membrosCelula = response.content;
                this.totalDePaginasMembros = response.totalPages;
                this.buscaMembros.quantityOfElements = response.quantityOfElements;
                const idMembros = this.membrosCelula.map((membro) => membro.id);
                yield this.carregarQuestionarioCelula(idMembros);
                for (let i = 0; i < this.membrosCelula.length; i++) {
                    let response = yield this.pessoaService.buscaPorId(this.membrosCelula[i].pessoaId);
                    let pessoa = response.entity;
                    this.membrosCelula[i].pessoaNome = pessoa.nome + " " + pessoa.sobrenome;
                    this.membrosCelula[i].pessoaImagemId = pessoa.imagemId;
                    this.membrosCelula[i].vinculoMissao = pessoa.vinculoMissao;
                    if (this.membrosCelula[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.membrosCelula[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.membrosCelula[i]);
                    }
                    let nivelFormativoMembro = this.niveisFormacao.find((obj) => obj.id === this.membrosCelula[i].nivelFormativoId);
                    this.membrosCelula[i].nivelFormativoNome = nivelFormativoMembro
                        ? nivelFormativoMembro.nome
                        : null;
                    const questionario = this.questionariosCelula.filter((questionarioFormando) => questionarioFormando.id === this.membrosCelula[i].id);
                    if (questionario.length > 0) {
                        this.membrosCelula[i].statusQuestionario =
                            questionario[0].status_questionario;
                        this.membrosCelula[i].questionarioFormandoId =
                            questionario[0].questionarioFormandoId;
                        this.membrosCelula[i].questionarioId = questionario[0].questionarioId;
                        this.membrosCelula[i].questionarioNome = this.buscarNomeQuestionario(this.membrosCelula[i].questionarioId);
                        this.membrosCelula[i].statusParecerFC =
                            questionario[0].status_parecer_fc;
                        this.membrosCelula[i].idParecerFC = questionario[0].parecerIdFC;
                        this.membrosCelula[i].acompanhadoPor = questionario[0].acompanhadoPor;
                        this.membrosCelula[i].acompanhadoPorNome =
                            questionario[0].acompanhadoPorNome;
                        this.membrosCelula[i].arquivo = questionario[0].arquivo;
                        this.membrosCelula[i].dataEnvioParecerFC =
                            questionario[0].dataEnvioParecerFC;
                        this.membrosCelula[i].dataEnvioQuestionario =
                            questionario[0].dataEnvioQuestionario;
                        this.membrosCelula[i].missaoAtualId = questionario[0].missaoAtualId;
                        this.membrosCelula[i].carta = questionario[0].carta;
                        this.membrosCelula[i].indicacaoCD = questionario[0].indicacaoCD;
                        this.membrosCelula[i].justificativaCD =
                            questionario[0].justificativaCD;
                        this.membrosCelula[i].indicacaoCL = questionario[0].indicacaoCL;
                        this.membrosCelula[i].justificativaCL =
                            questionario[0].justificativaCL;
                        this.membrosCelula[i].consideracaoCL = questionario[0].consideracaoCL;
                        this.membrosCelula[i].indicacaoCR = questionario[0].indicacaoCR;
                        this.membrosCelula[i].justificativaCR =
                            questionario[0].justificativaCR;
                        this.membrosCelula[i].justificativaGG =
                            questionario[0].justificativaGG;
                    }
                }
                if (this.membrosCelula.length > 0) {
                    this.membrosCelula = this.membrosCelula.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarQuestionarioCelula(idMembros) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorMembrosId(idMembros);
                this.questionariosCelula = response;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    consultarCaminhoFormativo(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.caminhoFormativoService.porPessoaId(formando.pessoaId);
                if (response != null && response != undefined) {
                    this.caminhoFormativo = response;
                }
                else {
                    this.caminhoFormativo = {};
                    this.caminhoFormativo.pessoaId = formando.pessoaId;
                    this.caminhoFormativo.missao_atual_id = formando.missaoAtualId;
                    this.caminhoFormativo.ano_formativo_id = 2;
                    this.caminhoFormativo.nome_formador_comunitario =
                        this.pessoa.nome + " " + this.pessoa.sobrenome;
                    let response = yield this.caminhoFormativoService.cadastrar(this.caminhoFormativo);
                    this.caminhoFormativo = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoQuestionarioCAL(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.consultarCaminhoFormativo(formando);
                let responsePorPessoa = yield this.questionarioFormandoService.buscarPorPessoaIdEQuestionarioId(formando.pessoaId, formando.questionarioId);
                if (responsePorPessoa != undefined &&
                    responsePorPessoa != null &&
                    responsePorPessoa.questionarioId == formando.questionarioId) {
                    this.questionarioFormando = responsePorPessoa;
                    this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                    //this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                }
                else {
                    this.questionarioFormando.anoFormativoId = 2;
                    this.questionarioFormando.questionarioId = formando.questionarioId;
                    this.questionarioFormando.pessoaId = formando.pessoaId;
                    let response = yield this.questionarioFormandoService.cadastrar(this.questionarioFormando);
                    if (response != undefined && response != null) {
                        this.questionarioFormando = response;
                        this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                        formando.questionarioFormandoId = this.questionarioFormando.id;
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoParecerCAL(questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                this.parecerResponsavel.parecer_id = 3;
                this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
                this.parecerResponsavel.tipo = "FC";
                let response = yield this.parecerResponsavelService.cadastrar(this.parecerResponsavel);
                if (response != undefined && response != null) {
                    this.parecerResponsavel = response;
                    this.router.navigate([
                        "/secured/parecer-cal-answer-default" +
                            "/" +
                            this.parecerResponsavel.id,
                    ]);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    buscarNomeQuestionario(idQuestionario) {
        let nomeQuestionario = "";
        if (idQuestionario) {
            switch (idQuestionario) {
                case 4:
                    nomeQuestionario = "Pedido para as Primeiras Promessas - CA";
                    break;
                case 5:
                    nomeQuestionario = "Pedido para as Promessas Definitivas - CA";
                    break;
                case 6:
                    nomeQuestionario = "Postulantes/Ingresso no Discipulado - CA";
                    break;
                case 7:
                    nomeQuestionario = "Discipulos de Primeiro Ano - CA";
                    break;
                case 8:
                    nomeQuestionario = "Renovação das Promessas - CA";
                    break;
                default:
                    nomeQuestionario = null;
                    break;
            }
        }
        return nomeQuestionario;
    }
    consultarVinculoQuestionarioCAL(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(formando);
            try {
                if (formando.questionarioFormandoId != undefined &&
                    formando.questionarioFormandoId != null) {
                    if (formando.idParecerFC != undefined && formando.idParecerFC != null) {
                        this.router.navigate([
                            "/secured/parecer-cal-answer-default" + "/" + formando.idParecerFC,
                        ]);
                    }
                    else {
                        this.cadastrarVinculoParecerCAL(formando.questionarioFormandoId);
                    }
                }
                else if (formando.questionarioFormandoId == undefined ||
                    formando.questionarioFormandoId == null) {
                    yield this.cadastrarVinculoQuestionarioCAL(formando);
                    yield this.cadastrarVinculoParecerCAL(formando.questionarioFormandoId);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarArquivoPDF(arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const blobResponse = yield this.arquivoService
                .obterDetalhesArquivoPDF(arquivoPDFNome)
                .toPromise();
            const url = URL.createObjectURL(blobResponse);
            this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
    }
    configModalUploadQuestionario(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.questionarioFormandoSelectedNome = formando.pessoaNome;
            this.questionarioSelectedId = formando.questionarioId;
            if (formando.questionarioFormandoId != undefined &&
                formando.questionarioFormandoId != null) {
                this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
            }
            else {
                yield this.cadastrarVinculoQuestionarioCAL(formando);
            }
        });
    }
    onFileSelected(event) {
        const file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === "application/pdf" &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFileArquivo = file;
            this.errorMessage = "";
        }
        else {
            this.selectedFileArquivo = null;
            this.errorMessage = "O arquivo deve ser um PDF com no máximo 3 MB.";
        }
        this.checkFormUploadValidity();
    }
    onUpload() {
        if (this.selectedFileArquivo && this.pedidoFormandoId) {
            this.questionarioFormandoService
                .uploadQuestionario(this.questionarioFormandoSelectedId, this.pedidoFormandoId, this.selectedFileArquivo)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: (data) => {
                    this.swtAlert2Service.successAlert(data);
                    this.carregarMembrosCelula();
                    $("#modal-upload-questionario").modal("hide");
                },
                error: (error) => {
                    this.swtAlert2Service.errorAlert("Erro ao realizar o upload do arquivo.");
                },
                complete: () => {
                    this.unsubscribe$.next();
                },
            });
            this.pedidoFormandoId = null;
            this.questionarioFormandoSelectedId = null;
            this.questionarioFormandoSelectedNome = null;
        }
        else {
            if (!this.pedidoFormandoId) {
                this.errorMessage =
                    "O campo 'Qual Pedido o seu do Formando está fazendo' deve ser informado.";
            }
            else {
                this.errorMessage =
                    "Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb";
            }
        }
    }
    onTipoPedidoChange() {
        this.checkFormUploadValidity();
    }
    // Verifica se tanto o arquivo quanto o tipo de pedido foram selecionados
    checkFormUploadValidity() {
        this.isFormValidUpload =
            !!this.pedidoFormandoId && !!this.selectedFileArquivo;
    }
    /**
     * CARTA
     */
    configModalUploadCarta(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.questionarioFormandoSelectedNome = formando.pessoaNome;
            if (formando.questionarioFormandoId != undefined &&
                formando.questionarioFormandoId != null) {
                this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
            }
            else {
                yield this.cadastrarVinculoQuestionarioCAL(formando);
            }
        });
    }
    carregarCartaPDF(arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const blobResponse = yield this.arquivoService
                .obterDetalhesArquivoPDF(arquivoPDFNome)
                .toPromise();
            const url = URL.createObjectURL(blobResponse);
            this.sanitizedPdfCartaUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
    }
    onCartaFileSelected(event) {
        const file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === "application/pdf" &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFileCarta = file;
            this.errorMessage = "";
        }
        else {
            this.selectedFileCarta = null;
            this.errorMessage = "O arquivo deve ser um PDF com no máximo 3 MB.";
        }
    }
    onCartaUpload() {
        if (this.selectedFileCarta) {
            this.questionarioFormandoService
                .uploadCarta(this.questionarioFormandoSelectedId, this.selectedFileCarta)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: (data) => {
                    this.swtAlert2Service.successAlert(data);
                    this.carregarMembrosCelula();
                    $("#modal-upload-carta").modal("hide");
                },
                error: (error) => {
                    this.swtAlert2Service.errorAlert("Erro ao realizar o upload do arquivo.");
                },
                complete: () => {
                    this.unsubscribe$.next();
                },
            });
            this.questionarioFormandoSelectedId = null;
            this.questionarioFormandoSelectedNome = null;
        }
        else {
            this.errorMessage =
                "Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb";
        }
    }
    removerArquivoQuestionario(questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover esse questionário?"))
                try {
                    const response = yield this.questionarioFormandoService
                        .removerArquivoQuestionario(questionarioFormandoId)
                        .toPromise();
                    yield this.carregarMembrosCelula();
                    this.swtAlert2Service.successAlert(response);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error);
                }
        });
    }
    removerArquivoCarta(questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover essa carta?"))
                try {
                    const response = yield this.questionarioFormandoService
                        .removerArquivoCarta(questionarioFormandoId)
                        .toPromise();
                    yield this.carregarMembrosCelula();
                    this.swtAlert2Service.successAlert(response);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error);
                }
        });
    }
    bloquearRemocaoDeArquivos(membroComunidade) {
        return (membroComunidade.indicacaoCL ||
            membroComunidade.justificativaCL ||
            membroComunidade.consideracaoCL ||
            membroComunidade.indicacaoCD ||
            membroComunidade.justificativaCD ||
            membroComunidade.indicacaoCR ||
            membroComunidade.justificativaCR ||
            membroComunidade.justificativaGG);
    }
    cliqueNoBotaoListener(botao) {
        this.buscaMembros.pageNumber = botao.numero - 1;
        this.carregarMembrosCelula();
    }
}

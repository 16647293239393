import * as tslib_1 from "tslib";
import { ElementRef, Renderer2, OnInit, AfterViewInit, } from "@angular/core";
import { UsuarioBuilder } from "src/app/builder/usuario.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
export class SecuredComponent {
    constructor(loginService, usuarioService, pessoaService, loggedInUserSharedDataService, routerManagerService, appRouteChangeService, //necessário para rodar as mudanças de rota na aplicação
    aceiteTermoService, aceiteTermoTratamentoDadosService, cadastroPublicoService, formadorComunitarioService, swtAlert2Service, router, renderer) {
        this.loginService = loginService;
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.loggedInUserSharedDataService = loggedInUserSharedDataService;
        this.routerManagerService = routerManagerService;
        this.appRouteChangeService = appRouteChangeService;
        this.aceiteTermoService = aceiteTermoService;
        this.aceiteTermoTratamentoDadosService = aceiteTermoTratamentoDadosService;
        this.cadastroPublicoService = cadastroPublicoService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.swtAlert2Service = swtAlert2Service;
        this.router = router;
        this.renderer = renderer;
        this.usuarioBuilder = new UsuarioBuilder();
        this.pessoaBuilder = new PessoaBuilder();
        this.usuarioAtual = this.usuarioBuilder.getInstance();
        this.pessoaAtual = this.pessoaBuilder.getInstance();
    }
    ngOnInit() {
        this.initialization();
    }
    ngAfterViewInit() {
        this.initializationAfterRender();
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loadTermsInfo();
            if (this.hasTermoUsoVigente == undefined) {
                yield this.carregarTermoUsoVigente();
            }
            if (this.hasTermoTratamentoDadosVigente == undefined) {
                yield this.carregarTermoTratamentoDadosVigente();
            }
            if (this.hasTermoUsoVigente && this.usuarioAceitouTermoUso != true) {
                yield this.didUserAcceptUserTerm();
            }
            if (this.hasTermoTratamentoDadosVigente &&
                this.usuarioAceitouTermoTratamentoDados != true) {
                yield this.didUserAcceptDataProcessingTerm();
            }
        });
    }
    initializationAfterRender() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getUsuarioAtual();
            if (this.usuarioAtual.id) {
                yield this.getPessoaAtualPorUsuarioId();
                yield this.verificarUsuario();
                yield this.liberarMenuPorAutorizacao();
            }
        });
    }
    liberarMenuPorAutorizacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let autorizacaoUsuarioLogado = this.usuarioAtual.autorizacoesNome.length > 0
                ? this.usuarioAtual.autorizacoesNome[0]
                : undefined;
            switch (autorizacaoUsuarioLogado) {
                case "ROLE_MISSIONARIO": {
                    this.renderer.removeChild(this.menuComissaoDiscernimentoRef.nativeElement.parentNode, this.menuComissaoDiscernimentoRef.nativeElement);
                    this.renderer.removeChild(this.menuCoordenadorColegiadoRef.nativeElement.parentNode, this.menuCoordenadorColegiadoRef.nativeElement);
                    this.renderer.removeChild(this.menuConselhoRef.nativeElement.parentNode, this.menuConselhoRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioCasaRef.nativeElement.parentNode, this.menuFormadorComunitarioCasaRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorAssistenteRef.nativeElement.parentNode, this.menuFormadorAssistenteRef.nativeElement);
                    this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                    break;
                }
                case "ROLE_FORMADOR_ASSISTENTE": {
                    this.renderer.removeChild(this.menuComissaoDiscernimentoRef.nativeElement.parentNode, this.menuComissaoDiscernimentoRef.nativeElement);
                    this.renderer.removeChild(this.menuCoordenadorColegiadoRef.nativeElement.parentNode, this.menuCoordenadorColegiadoRef.nativeElement);
                    this.renderer.removeChild(this.menuConselhoRef.nativeElement.parentNode, this.menuConselhoRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioCasaRef.nativeElement.parentNode, this.menuFormadorComunitarioCasaRef.nativeElement);
                    this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                    this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                    break;
                }
                case "ROLE_FORMADOR_COMUNITARIO": {
                    this.carregarFormadorComunitario();
                    this.renderer.removeChild(this.menuComissaoDiscernimentoRef.nativeElement.parentNode, this.menuComissaoDiscernimentoRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorAssistenteRef.nativeElement.parentNode, this.menuFormadorAssistenteRef.nativeElement);
                    this.renderer.removeChild(this.menuCoordenadorColegiadoRef.nativeElement.parentNode, this.menuCoordenadorColegiadoRef.nativeElement);
                    this.renderer.removeChild(this.menuConselhoRef.nativeElement.parentNode, this.menuConselhoRef.nativeElement);
                    this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                    this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                    break;
                }
                case "ROLE_COMISSAO_DISCERNIMENTO": {
                    this.renderer.removeChild(this.menuCoordenadorColegiadoRef.nativeElement.parentNode, this.menuCoordenadorColegiadoRef.nativeElement);
                    this.renderer.removeChild(this.menuConselhoRef.nativeElement.parentNode, this.menuConselhoRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorAssistenteRef.nativeElement.parentNode, this.menuFormadorAssistenteRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioCasaRef.nativeElement.parentNode, this.menuFormadorComunitarioCasaRef.nativeElement);
                    this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                    this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                    break;
                }
                case "ROLE_COORDENADOR_COLEGIADO": {
                    this.renderer.removeChild(this.menuComissaoDiscernimentoRef.nativeElement.parentNode, this.menuComissaoDiscernimentoRef.nativeElement);
                    this.renderer.removeChild(this.menuConselhoRef.nativeElement.parentNode, this.menuConselhoRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorAssistenteRef.nativeElement.parentNode, this.menuFormadorAssistenteRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioCasaRef.nativeElement.parentNode, this.menuFormadorComunitarioCasaRef.nativeElement);
                    this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                    this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                    break;
                }
                case "ROLE_CONSELHO": {
                    this.renderer.removeChild(this.menuComissaoDiscernimentoRef.nativeElement.parentNode, this.menuComissaoDiscernimentoRef.nativeElement);
                    this.renderer.removeChild(this.menuCoordenadorColegiadoRef.nativeElement.parentNode, this.menuCoordenadorColegiadoRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorAssistenteRef.nativeElement.parentNode, this.menuFormadorAssistenteRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioCasaRef.nativeElement.parentNode, this.menuFormadorComunitarioCasaRef.nativeElement);
                    this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                    this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                    break;
                }
                case "ROLE_ADMIN": {
                    this.renderer.removeChild(this.menuComissaoDiscernimentoRef.nativeElement.parentNode, this.menuComissaoDiscernimentoRef.nativeElement);
                    this.renderer.removeChild(this.menuConselhoRef.nativeElement.parentNode, this.menuConselhoRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorAssistenteRef.nativeElement.parentNode, this.menuFormadorAssistenteRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioCasaRef.nativeElement.parentNode, this.menuFormadorComunitarioCasaRef.nativeElement);
                    this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                    this.renderer.removeChild(this.menuAcessoNegadoRef.nativeElement.parentNode, this.menuAcessoNegadoRef.nativeElement);
                    break;
                }
                default: {
                    this.renderer.removeChild(this.menuComissaoDiscernimentoRef.nativeElement.parentNode, this.menuComissaoDiscernimentoRef.nativeElement);
                    this.renderer.removeChild(this.menuCoordenadorColegiadoRef.nativeElement.parentNode, this.menuCoordenadorColegiadoRef.nativeElement);
                    this.renderer.removeChild(this.menuConselhoRef.nativeElement.parentNode, this.menuConselhoRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorComunitarioCasaRef.nativeElement.parentNode, this.menuFormadorComunitarioCasaRef.nativeElement);
                    this.renderer.removeChild(this.menuFormadorAssistenteRef.nativeElement.parentNode, this.menuFormadorAssistenteRef.nativeElement);
                    this.renderer.removeChild(this.menuMissionarioRef.nativeElement.parentNode, this.menuMissionarioRef.nativeElement);
                }
            }
        });
    }
    carregarTermoUsoVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoService.getTermoVigente();
                this.termoUsoVigente = response.entity;
                this.hasTermoUsoVigente = this.termoUsoVigente ? true : false;
                sessionStorage.setItem("hasTermoUsoVigente", this.hasTermoUsoVigente ? "1" : "0");
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarTermoTratamentoDadosVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoService.getTermoTratamentoDadosVigente();
                this.termoTratamentoDadosVigente = response.entity;
                this.hasTermoTratamentoDadosVigente = this.termoTratamentoDadosVigente
                    ? true
                    : false;
                sessionStorage.setItem("hasTermoTratamentoDadosVigente", this.hasTermoTratamentoDadosVigente ? "1" : "0");
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response2 = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response2.entity;
                this.loggedInUserSharedDataService.updateLoggedInUser(this.usuarioAtual);
            }
            catch (e) {
                if (e.status > 400 && e.status < 500) {
                    this.swtAlert2Service.errorAlert("Seu usuário não tem autorização para acessar esse módulo.");
                }
                else {
                    this.swtAlert2Service.errorAlert("Não foi possível realizar o login. O sistema pode estar em manutenção, caso o problema persista tente realizar o login novamente mais tarde!");
                }
                this.router.navigate(["/login"]);
            }
        });
    }
    getPessoaAtualPorUsuarioId() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id);
            this.pessoaAtual = response.entity;
            if (JSON.parse(sessionStorage.getItem("pessoaAtual")) == undefined ||
                JSON.parse(sessionStorage.getItem("pessoaAtual")) == null) {
                sessionStorage.setItem("pessoaAtual", JSON.stringify(this.pessoaAtual));
            }
            this.loggedInUserSharedDataService.updateLoggedInPerson(response.entity);
        });
    }
    didUserAcceptUserTerm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.aceiteTermoService.usuarioAceitouTermo();
                this.usuarioAceitouTermoUso = response.entity;
                sessionStorage.setItem("usuarioAceitouTermoUso", this.usuarioAceitouTermoUso ? "1" : "0");
                if (!this.usuarioAceitouTermoUso) {
                    this.router.navigate(["/aceitetermouso"]);
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
                this.router.navigate(["/login"]);
            }
        });
    }
    didUserAcceptDataProcessingTerm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.aceiteTermoTratamentoDadosService.usuarioAceitouTermo();
                this.usuarioAceitouTermoTratamentoDados = response.entity;
                sessionStorage.setItem("usuarioAceitouTermoTratamentoDados", this.usuarioAceitouTermoTratamentoDados ? "1" : "0");
                if (!this.usuarioAceitouTermoTratamentoDados) {
                    this.router.navigate(["/aceitetermotratamentodados"]);
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
                this.router.navigate(["/login"]);
            }
        });
    }
    verificarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.routerManagerService.verificarUsuario(this.usuarioAtual);
        });
    }
    loadTermsInfo() {
        if (sessionStorage.getItem("usuarioAceitouTermoUso") != null)
            this.usuarioAceitouTermoUso =
                sessionStorage.getItem("usuarioAceitouTermoUso").localeCompare("1") == 0
                    ? true
                    : false;
        if (sessionStorage.getItem("usuarioAceitouTermoTratamentoDados") != null)
            this.usuarioAceitouTermoTratamentoDados =
                sessionStorage
                    .getItem("usuarioAceitouTermoTratamentoDados")
                    .localeCompare("1") == 0
                    ? true
                    : false;
        if (sessionStorage.getItem("hasTermoUsoVigente") != null)
            this.hasTermoUsoVigente =
                sessionStorage.getItem("hasTermoUsoVigente").localeCompare("1") == 0
                    ? true
                    : false;
        if (sessionStorage.getItem("hasTermoTratamentoDadosVigente") != null)
            this.hasTermoTratamentoDadosVigente =
                sessionStorage
                    .getItem("hasTermoTratamentoDadosVigente")
                    .localeCompare("1") == 0
                    ? true
                    : false;
    }
    carregarFormadorComunitario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let responseCelula = yield this.formadorComunitarioService.buscarCelulaPorPessoaId(this.pessoaAtual.id);
                this.formadorComunitarioCelula = responseCelula;
                let responseCasa = yield this.formadorComunitarioService.buscarCasaComunitariaPorPessoaId(this.pessoaAtual.id);
                this.formadorComunitarioCasa = responseCasa;
                if (this.formadorComunitarioCelula != undefined &&
                    this.formadorComunitarioCelula != null) {
                    this.renderer.removeChild(this.menuFormadorComunitarioCasaRef.nativeElement.parentNode, this.menuFormadorComunitarioCasaRef.nativeElement);
                }
                else if (this.formadorComunitarioCasa != undefined &&
                    this.formadorComunitarioCasa != null) {
                    this.renderer.removeChild(this.menuFormadorComunitarioRef.nativeElement.parentNode, this.menuFormadorComunitarioRef.nativeElement);
                }
            }
            catch (e) {
                console.log(e);
            }
        });
    }
}

import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from "@angular/core";
import { MembroComunidadeBuilder } from "src/app/builder/membro.comunidade.builder";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
export class ParecerCelulaComissaoComponent {
    constructor(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, elementoGrupoDominioLocalService, caminhoFormativoService, arquivoService, sanitizer, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.totalDePaginas = 1;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
        this.hasCelulaAssociada = false;
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            this.celulaId = Number(this.route.snapshot.paramMap.get("id"));
            // await this.carregarCorStatusVocacional();
            yield this.carregarMembrosCelula();
        });
    }
    // async carregarCorStatusVocacional(): Promise<any> {
    //   try {
    //     this.listaCorStatusVocacional =
    //       await this.elementoGrupoDominioLocalService.buscarPorGrupoDominioNomeModuloFormacao(
    //         "COR_STATUS_VOCACIONAL"
    //       );
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    carregarQuestionarioConfiguracao(questionarioId) {
        const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe((questionarioConfiguracao) => (this.questionarioConfiguracao = questionarioConfiguracao));
    }
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMembrosCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.membrosCelula =
                    yield this.membroService.listarDetalhesPorCelulaIdFromView(this.celulaId);
                this.totalDePaginasMembros = 1;
                if (this.membrosCelula.length > 0) {
                    this.membrosCelula = this.membrosCelula.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                const idMembros = this.membrosCelula.map((membro) => membro.id);
                yield this.carregarQuestionarioMembro(idMembros);
                console.log(this.questionariosMembros);
                for (let i = 0; i < this.membrosCelula.length; i++) {
                    let response = yield this.pessoaService.buscaPorId(this.membrosCelula[i].pessoaId);
                    let pessoa = response.entity;
                    this.membrosCelula[i].pessoaImagemId = pessoa.imagemId;
                    if (this.membrosCelula[i].pessoaImagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.membrosCelula[i].pessoaImagemId);
                        this.imagemService.createImage(blogImage, this.membrosCelula[i]);
                    }
                    this.membrosCelula[i].editando = false;
                    const questionario = this.questionariosMembros.filter((questionarioFormando) => questionarioFormando.id === this.membrosCelula[i].id);
                    if (questionario.length > 0) {
                        this.membrosCelula[i].statusQuestionario =
                            questionario[0].status_questionario;
                        this.membrosCelula[i].idQuestionarioFormando =
                            questionario[0].questionarioFormandoId;
                        this.membrosCelula[i].questionarioId = questionario[0].questionarioId;
                        this.membrosCelula[i].statusParecerFC =
                            questionario[0].status_parecer_fc;
                        this.membrosCelula[i].idParecerFC = questionario[0].parecerIdFC;
                        this.membrosCelula[i].acompanhadoPor = questionario[0].acompanhadoPor;
                        this.membrosCelula[i].acompanhadoPorNome =
                            questionario[0].acompanhadoPorNome;
                        this.membrosCelula[i].arquivo = questionario[0].arquivo;
                        this.membrosCelula[i].dataEnvioParecerFC =
                            questionario[0].dataEnvioParecerFC;
                        this.membrosCelula[i].dataEnvioQuestionario =
                            questionario[0].dataEnvioQuestionario;
                        this.membrosCelula[i].missaoAtualId =
                            questionario[0].pessoamissaoAtualId;
                        this.membrosCelula[i].questionarioNome = this.buscarNomeQuestionario(this.questionarioFormando.questionarioId);
                        //this.membrosCelula[i].pedidoFormando = this.buscarNomePedido(questionario[0].indicacaoFC);
                        this.membrosCelula[i].indicacaoFCNome = this.buscarNomePedido(questionario[0].indicacaoFC);
                        this.membrosCelula[i].justificativaFC =
                            questionario[0].justificativaFC;
                        this.membrosCelula[i].indicacaoCD = questionario[0].indicacaoCD;
                        this.membrosCelula[i].indicacaoCDNome = this.buscarNomePedido(questionario[0].indicacaoCD);
                        this.membrosCelula[i].justificativaCD =
                            questionario[0].justificativaCD;
                    }
                    if (this.membrosCelula[i].statusQuestionario == "NAOINICIADO" ||
                        this.membrosCelula[i].statusQuestionario == undefined ||
                        this.membrosCelula[i].statusQuestionario == null) {
                        this.membrosCelula[i].statusQuestionario = "Não Iniciado";
                    }
                    if (this.membrosCelula[i].statusParecerFC == "NAOINICIADO" ||
                        this.membrosCelula[i].statusParecerFC == undefined ||
                        this.membrosCelula[i].statusParecerFC == null) {
                        this.membrosCelula[i].statusParecerFC = "Não Iniciado";
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    buscarNomePedido(idPedido) {
        let nomePedido = "";
        if (idPedido) {
            switch (idPedido) {
                case 1:
                    nomePedido = "Mudança de Nível";
                    break;
                case 2:
                    nomePedido = "Permanecer no Nível";
                    break;
                case 3:
                    nomePedido = "Realizar Primeiras Promessas";
                    break;
                case 4:
                    nomePedido = "Realizar Promessas Definitivas";
                    break;
                case 5:
                    nomePedido = "Desligamento CAL";
                    break;
                case 6:
                    nomePedido = "Outro";
                    break;
                default:
                    nomePedido = null;
                    break;
            }
        }
        return nomePedido;
    }
    buscarNomeQuestionario(idQuestionario) {
        let nomeQuestionario = "";
        if (idQuestionario) {
            switch (idQuestionario) {
                case 4:
                    nomeQuestionario = "Pedido para as Primeiras Promessas - CA";
                    break;
                case 5:
                    nomeQuestionario = "Pedido para as Promessas Definitivas - CA";
                    break;
                case 6:
                    nomeQuestionario = "Postulantes/Ingresso no Discipulado - CA";
                    break;
                case 7:
                    nomeQuestionario = "Discipulos de Primeiro Ano - CA";
                    break;
                case 8:
                    nomeQuestionario = "Renovação das Promessas - CA";
                    break;
                default:
                    nomeQuestionario = null;
                    break;
            }
        }
        return nomeQuestionario;
    }
    carregarQuestionarioMembro(idMembros) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorMembrosId(idMembros);
                this.questionariosMembros = response;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarArquivoPDF(arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const blobResponse = yield this.arquivoService
                .obterDetalhesArquivoPDF(arquivoPDFNome)
                .toPromise();
            const url = URL.createObjectURL(blobResponse);
            this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
    }
    cadastrarConsideracaoComissao(membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.atualizarQuestionarioFormando(membro);
                yield this.carregarMembrosCelula();
                this.swtAlert2Service.successAlert("Parecer de consideração da Comissão de Discernimento atualizado com sucesso!");
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarQuestionarioFormando(membro) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let responseQuestionario = yield this.questionarioFormandoService.buscarPorId(membro.idQuestionarioFormando);
                this.questionarioFormando = responseQuestionario;
                if (this.questionarioFormando.id != null) {
                    this.questionarioFormando.indicacaoCD = membro.indicacaoCD;
                    this.questionarioFormando.justificativaCD = membro.justificativaCD;
                    let response = yield this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando);
                    this.questionarioFormando = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.carregarMembrosCelula();
    }
    truncateText(text, limit = 50) {
        if (!text) {
            return '';
        }
        return text.length > limit ? text.substring(0, limit) + '...' : text;
    }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { ElementoGrupoDominio } from "src/app/model/elementogrupodominio.model";
import { Pessoa } from "src/app/model/pessoa.model";
import { DadoVocacional } from "src/app/model/dadovocacional.model";
import { PessoaBuilder } from "src/app/builder/pessoa.builder";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { Usuario } from "src/app/model/usuario.model";
import { RespostaParecer } from "src/app/model/respostaparecer.model";
import { ParecerResponsavel } from "src/app/model/parecerresponsavel.model";
import { QuestionarioFormando } from "src/app/model/questionarioformando.model";
import { Estado } from "src/app/model/estado.model";
import { Pais } from "src/app/model/pais.model";
import { Cidade } from "src/app/model/cidade.model";
import { CaminhoFormativo } from "src/app/model/caminhoformativo.model";
import { PessoaInfo } from "src/app/model/pessoainfo.model";
import { Missao } from "src/app/model/missao.model";
import { DadoFormandoCABuilder } from "src/app/builder/dadoformandoca.builder";
import { CaminhoFormativoBuilder } from "src/app/builder/caminhoformativo.builder";
import { QuestionarioFormandoBuilder } from "src/app/builder/questionarioformando.builder";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { RespostaParecerBuilder } from "src/app/builder/respostaparecer.builder";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { PessoaInfoBuilder } from "src/app/builder/pessoainfo.builder";
import { PaisService } from "src/app/secured/service/pais.service";
import { EstadoService } from "src/app/secured/service/estado.service";
import { CidadeService } from "src/app/secured/service/cidade.service";
import { ElementoGrupoDominioService } from "src/app/secured/service/elementogrupodominio.service";
import { MissaoService } from "src/app/secured/service/missao.service";
import { WopGetAllService } from "src/app/service/getall.service";
import { DadoFormandoCA } from "src/app/model/dadoformandoca.model";
import { DadoFormandoCV } from "src/app/model/dadoformandocv.model";
import { NgForm } from "@angular/forms";
import { PessoaService } from "src/app/secured/component/pessoa/service/pessoa.service";
import { ImagemService } from "src/app/secured/service/imagem.service";
import { ElementoGrupoDominioLocalService } from "src/app/secured/service/elementogrupodominio.local.service";
import { UsuarioService } from "src/app/secured/component/usuario/service/usuario.service";
import { DadoFormandoCAService } from "src/app/secured/component/parecer/service/dadoformandoca.service";
import { CaminhoFormativoService } from "src/app/secured/component/questionario/service/caminhoformativo.service";
import { RespostaParecerService } from "src/app/secured/component/parecer/service/respostaparecer.service";
import { QuestionarioFormandoService } from "src/app/secured/component/questionario/service/questionarioformando.service";
import { ParecerResponsavelService } from "src/app/secured/component/parecer/service/parecerresponsavel.service";
import { DadoVocacionalService } from "src/app/secured/component/dadosvocacionais/service/dadovocacional.service";

@Component({
  selector: "app-parecer-cal-answer-view",
  templateUrl: "./parecer-cal-answer-default-view-public.component.html",
  styleUrls: [],
})
export class ParecerCALAnswerDefaultViewPublicComponent implements OnInit {
  @ViewChild("dadosPessoaisForm", { static: true })
  dadosPessoaisForm!: NgForm;

  public pessoaResponsavel: Pessoa;
  public missoesVinculo: Array<Missao>;
  public missoes: Array<Missao>;
  public pessoaFormandoId: number;
  public tabNumber: number;
  public imageChangedEvent: any;
  public croppedImage: any;
  public nextClicked: boolean;
  public url: string;
  public intlTelInputCalled: boolean = false;
  public intlTellInputInstance: any;
  public pessoaFormadorPessoalSelecionado: PessoaInfo;
  public dadoVocacional: DadoVocacional;
  public dadoFormandoCA: DadoFormandoCA;
  public dadoFormandoCV: DadoFormandoCV;
  public caminhoFormativoFormando: CaminhoFormativo;
  public urlBaseImagem: string;
  public formando: Pessoa;

  public imageUrl: any;

  public tabNames: Array<string>;
  public activeTabNumber: number;

  public paises: Array<Pais>;
  public estados: Array<Estado>;
  public cidades: Array<Cidade>;

  public parecerResponsavelId: number;
  public parecerResponsavel: ParecerResponsavel;
  public questionarioFormando: QuestionarioFormando;
  public questionarioNome: string;  

  public respostas: Array<RespostaParecer>;

  public resposta36: RespostaParecer;
  public resposta37: RespostaParecer;
  public resposta38: RespostaParecer;
  public resposta39: RespostaParecer;
  public resposta40: RespostaParecer;
  public resposta41: RespostaParecer;
  public resposta42: RespostaParecer;
  public resposta43: RespostaParecer;
  public resposta44: RespostaParecer;
  public resposta45: RespostaParecer;
  public resposta46: RespostaParecer;
  public resposta47: RespostaParecer;
  public resposta48: RespostaParecer;
  public resposta49: RespostaParecer;
  public resposta50: RespostaParecer;
  public resposta51: RespostaParecer;
  public resposta52: RespostaParecer;
  public resposta53: RespostaParecer;
  public resposta54: RespostaParecer;
  public resposta55: RespostaParecer;
  public resposta56: RespostaParecer;
  public resposta57: RespostaParecer;
  public resposta58: RespostaParecer;
  public resposta59: RespostaParecer;
  public resposta60: RespostaParecer;
  public resposta61: RespostaParecer;
  public resposta62: RespostaParecer;
  public resposta63: RespostaParecer;
  public resposta64: RespostaParecer;
  public resposta65: RespostaParecer;
  public resposta66: RespostaParecer;
  public resposta67: RespostaParecer;
  public resposta68: RespostaParecer;
  public resposta69: RespostaParecer;
  public resposta70: RespostaParecer;
  public resposta71: RespostaParecer;
  public resposta72: RespostaParecer;
  public resposta73: RespostaParecer;
  public resposta74: RespostaParecer;
  public resposta75: RespostaParecer;
  public resposta76: RespostaParecer;
  public resposta77: RespostaParecer;

  constructor(
    public wopGetAllPublic: WopGetAllService,
    public pessoaService: PessoaService,
    public imagemService: ImagemService,
    public paisService: PaisService,
    public estadoService: EstadoService,
    public cidadeService: CidadeService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public missaoService: MissaoService,
    public swtAlert2Service: SwtAlert2Service,
    public usuarioService: UsuarioService,
    public pessoaBuilder: PessoaBuilder,
    public pessoaInfoBuilder: PessoaInfoBuilder,
    public dadoVocacionalService: DadoVocacionalService,
    public dadoFormandoCAService: DadoFormandoCAService,
    public caminhoFormativoService: CaminhoFormativoService,
    public respostaParecerService: RespostaParecerService,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelService: ParecerResponsavelService,
    public dadoFormandoCABuilder: DadoFormandoCABuilder,
    public caminhoFormativoFormandoBuilder: CaminhoFormativoBuilder,
    public respostaParecerBuilder: RespostaParecerBuilder,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.missoesVinculo = new Array<Missao>();
    this.missoes = new Array<Missao>();
    this.dadoFormandoCA = this.dadoFormandoCABuilder.getInstance();
    this.caminhoFormativoFormando =
      this.caminhoFormativoFormandoBuilder.getInstance();
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.nextClicked = false;

    this.pessoaResponsavel = this.pessoaBuilder.getInstance();
    this.formando = this.pessoaBuilder.getInstance();

    this.urlBaseImagem = environment.imagem.urlBaseImagem;
    this.url = environment.usuario.selectPorUsername;

    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();

    this.resposta36 = respostaParecerBuilder.getInstance();
    this.resposta37 = respostaParecerBuilder.getInstance();
    this.resposta38 = respostaParecerBuilder.getInstance();
    this.resposta39 = respostaParecerBuilder.getInstance();
    this.resposta40 = respostaParecerBuilder.getInstance();
    this.resposta41 = respostaParecerBuilder.getInstance();
    this.resposta42 = respostaParecerBuilder.getInstance();
    this.resposta43 = respostaParecerBuilder.getInstance();
    this.resposta44 = respostaParecerBuilder.getInstance();
    this.resposta45 = respostaParecerBuilder.getInstance();
    this.resposta46 = respostaParecerBuilder.getInstance();
    this.resposta47 = respostaParecerBuilder.getInstance();
    this.resposta48 = respostaParecerBuilder.getInstance();
    this.resposta49 = respostaParecerBuilder.getInstance();
    this.resposta50 = respostaParecerBuilder.getInstance();
    this.resposta51 = respostaParecerBuilder.getInstance();
    this.resposta52 = respostaParecerBuilder.getInstance();
    this.resposta53 = respostaParecerBuilder.getInstance();
    this.resposta54 = respostaParecerBuilder.getInstance();
    this.resposta55 = respostaParecerBuilder.getInstance();
    this.resposta56 = respostaParecerBuilder.getInstance();
    this.resposta57 = respostaParecerBuilder.getInstance();
    this.resposta58 = respostaParecerBuilder.getInstance();
    this.resposta59 = respostaParecerBuilder.getInstance();
    this.resposta60 = respostaParecerBuilder.getInstance();
    this.resposta61 = respostaParecerBuilder.getInstance();
    this.resposta62 = respostaParecerBuilder.getInstance();
    this.resposta63 = respostaParecerBuilder.getInstance();
    this.resposta64 = respostaParecerBuilder.getInstance();
    this.resposta65 = respostaParecerBuilder.getInstance();
    this.resposta66 = respostaParecerBuilder.getInstance();
    this.resposta67 = respostaParecerBuilder.getInstance();
    this.resposta68 = respostaParecerBuilder.getInstance();
    this.resposta69 = respostaParecerBuilder.getInstance();
    this.resposta70 = respostaParecerBuilder.getInstance();
    this.resposta71 = respostaParecerBuilder.getInstance();
    this.resposta72 = respostaParecerBuilder.getInstance();
    this.resposta73 = respostaParecerBuilder.getInstance();
    this.resposta74 = respostaParecerBuilder.getInstance();
    this.resposta75 = respostaParecerBuilder.getInstance();
    this.resposta76 = respostaParecerBuilder.getInstance();
    this.resposta77 = respostaParecerBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initialization();
  }

  imprimirValorDoForm() {
    console.log("Valor do Form", JSON.stringify(this.dadosPessoaisForm.value));
  }

  async initialization(): Promise<any> {
    //await this.carregarMissoesVinculo();
    this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get("id"));
    await this.carregarParecerResponsavel();
    await this.carregarQuestionarioFormando();
    /*await this.carregarPessoaFormando(this.questionarioFormando.pessoaId);
    await this.carregarDadoVocacional();
    await this.carregarDadoFormandoCA();
    await this.carregarCaminhoFormativo();*/
    await this.carregarRespostas();
    // this.imprimirValorDoForm();
  }

  async carregarMissoesVinculo(): Promise<any> {
    try {
      let response = await this.wopGetAllPublic.getAllMissao();
      this.missoesVinculo = response.entity;
      this.missoesVinculo = this.missoesVinculo.sort((a: Missao, b: Missao) =>
        a.nome.localeCompare(b.nome)
      );
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarPessoaFormando(pessoaId: number): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorId(pessoaId);

      this.formando = response.entity;

      this.pessoaFormandoId = this.formando.id;

      if (this.formando.imagemId != null) {
        let blogImage = await this.getPessoaImagem(this.formando.imagemId);

        this.imagemService.createImage(blogImage, this.formando);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarPessoaResponsavel(usuarioId: number): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(usuarioId);

      return response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  logOnConsole(dadosPessoaisForm: any): void {
    console.log(dadosPessoaisForm);
  }

  activateTab(tab: string): void {
    $('.nav-tabs a[href="#' + tab + '"]').removeClass("disabled");
    $('.nav-tabs a[href="#' + tab + '"]').tab("show");
  }

  activateTab2(action: string): void {
    if (action.localeCompare("previous") == 0) {
      if (this.activeTabNumber > 0) {
        this.activeTabNumber -= 1;
      }
    } else if (action.localeCompare("next") == 0) {
      if (this.activeTabNumber < this.tabNames.length - 1) {
        this.activeTabNumber += 1;
      }
    }
    $(
      '.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]'
    ).removeClass("disabled");
    $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab(
      "show"
    );
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    console.log(event);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    console.log("Imagem carregada");
  }

  cropperReady() {
    console.log("Imagem cortada");
  }

  loadImageFailed() {
    console.log("Carregamento da imagem falhou!");
  }

  async carregarDadoVocacional(): Promise<any> {
    try {
      let response = await this.dadoVocacionalService.porPessoaId(
        this.formando.id
      );
      if (response.entity != undefined && response.entity != null) {
        this.dadoVocacional = response.entity;
        this.pessoaFormadorPessoalSelecionado =
          this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado.nome =
          this.dadoVocacional.formadorPessoalPessoaNome;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async selecionarFormadorComunitario(pessoa: PessoaInfo): Promise<any> {
    //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
    //this.pessoaFormadorComunitarioSelecionado = pessoa;
    //$('#listagem-pessoa-formador-comunitario').modal('hide');
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarDadoFormandoCA(): Promise<any> {
    try {
      let response = await this.dadoFormandoCAService.porPessoaId(
        this.formando.id
      );
      if (response != null && response != undefined) {
        this.dadoFormandoCA = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarCaminhoFormativo(): Promise<any> {
    try {
      let response = await this.caminhoFormativoService.porPessoaId(
        this.formando.id
      );
      if (response != null && response != undefined) {
        this.caminhoFormativoFormando = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarCaminhoFormativo(): Promise<any> {
    try {
      if (this.caminhoFormativoFormando.id != null) {
        let response = await this.caminhoFormativoService.atualizar(
          this.caminhoFormativoFormando
        );
        this.caminhoFormativoFormando = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarRespostas(): Promise<any> {
    try {
      let response = await this.respostaParecerService.buscaPublicaPorPessoaIdEParecerId(
        this.questionarioFormando.pessoaId, this.parecerResponsavel.parecer_id
      );
      if (response != null && response != undefined) {
        this.respostas = response;
        console.log(this.respostas);
        this.respostas.forEach((resposta) => {
          switch (resposta.parecer_pergunta_id) {
            case 36:
              this.resposta36 = resposta;
              break;
            case 37:
              this.resposta37 = resposta;
              break;
            case 38:
              this.resposta38 = resposta;
              break;
            case 39:
              this.resposta39 = resposta;
              break;
            case 40:
              this.resposta40 = resposta;
              break;
            case 41:
              this.resposta41 = resposta;
              break;
            case 42:
              this.resposta42 = resposta;
              break;
            case 43:
              this.resposta43 = resposta;
              break;
            case 44:
              this.resposta44 = resposta;
              break;
            case 45:
              this.resposta45 = resposta;
              break;
            case 46:
              this.resposta46 = resposta;
              break;
            case 47:
              this.resposta47 = resposta;
              break;
            case 48:
              this.resposta48 = resposta;
              break;
            case 49:
              this.resposta49 = resposta;
              break;
            case 50:
              this.resposta50 = resposta;
              break;
            case 51:
              this.resposta51 = resposta;
              break;
            case 52:
              this.resposta52 = resposta;
              break;
            case 53:
              this.resposta53 = resposta;
              break;
            case 54:
              this.resposta54 = resposta;
              break;
            case 55:
              this.resposta55 = resposta;
              break;
            case 56:
              this.resposta56 = resposta;
              break;
            case 57:
              this.resposta57 = resposta;
              break;
            case 58:
              this.resposta58 = resposta;
              break;
            case 59:
              this.resposta59 = resposta;
              break;
            case 60:
              this.resposta60 = resposta;
              break;
            case 61:
              this.resposta61 = resposta;
              break;
            case 62:
              this.resposta62 = resposta;
              break;
            case 63:
              this.resposta63 = resposta;
              break;
            case 64:
              this.resposta64 = resposta;
              break;
            case 65:
              this.resposta65 = resposta;
              break;
            case 66:
              this.resposta66 = resposta;
              break;
            case 67:
              this.resposta67 = resposta;
              break;
            case 68:
              this.resposta68 = resposta;
              break;
            case 69:
              this.resposta69 = resposta;
              break;
            case 70:
              this.resposta70 = resposta;
              break;
            case 71:
              this.resposta71 = resposta;
              break;
            case 72:
              this.resposta72 = resposta;
              break;
            case 73:
              this.resposta73 = resposta;
              break;
            case 74:
              this.resposta74 = resposta;
              break;
            case 75:
              this.resposta75 = resposta;
              break;
            case 76:
              this.resposta76 = resposta;
              break; 
            case 77:
              this.resposta77 = resposta;
              break;                            
          }
        });
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarParecerResponsavel(): Promise<any> {
    try {
      let response = await this.parecerResponsavelService.buscaPublicaPorId(
        this.parecerResponsavelId
      );
      if (response != null && response != undefined) {
        this.parecerResponsavel = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarQuestionarioFormando(): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscaPublicaPorId(
        this.parecerResponsavel.questionarioFormandoId
      );
      if (response != null && response != undefined) {
        this.questionarioFormando = response;
        switch (this.questionarioFormando.questionarioId) {
          case 4:
            this.questionarioNome = 'Pedido para as Primeiras Promessas - CA';
            break;      
          case 5:
            this.questionarioNome = 'Pedido para as Promessas Definitivas - CA';
            break;  
          case 6:
            this.questionarioNome = 'Postulantes/Ingresso no Discipulado - CA';
            break;      
          case 7:
            this.questionarioNome = 'Discipulos de Primeiro Ano - CA';
            break;
          case 8:
            this.questionarioNome = 'Renovação das Promessas - CA';
            break;                        
          default:
            this.questionarioNome = null;
            break;
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  respostaVazia(resposta: any): boolean {
    return (
      resposta.resposta == null ||
      resposta.resposta == undefined ||
      resposta.resposta == ""
    );
  }

}

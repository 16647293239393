import { GenericService } from "src/app/core/services/generic.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { QuestionarioFormando } from "src/app/model/questionarioformando.model";
import { Observable } from "rxjs";

@Injectable()
export class QuestionarioFormandoService extends GenericService<QuestionarioFormando> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType("questionarioformando");
  }

  buscarPorPessoaId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/porpessoaid/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  buscarPorPessoaIdEQuestionarioId(
    pessoaId: number,
    questionarioid: number
  ): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/pessoa/${pessoaId}/questionario/${questionarioid}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  buscarPorCelulaId(busca: any): Promise<any> {
    return this.http
      .post<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/porcelulaid`,
        JSON.stringify(busca),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  buscarPorMembrosId(busca: any): Promise<any> {
    return this.http
      .post<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/pormembrosid/celula`,
        JSON.stringify(busca),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  buscarPorCasaComunitariaId(busca: any): Promise<any> {
    return this.http
      .post<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/porcasaid`,
        JSON.stringify(busca),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  buscarPorId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/${id}`,
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  buscaPublicaPorId(id: number): Promise<any> {
    return this.http
      .get<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/pb/${id}`)
      .toPromise();
  }  

  cadastrar(request: any) {
    return this.http
      .post<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  atualizar(id: number, request: any) {
    return this.http
      .put<any>(
        `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/${id}`,
        JSON.stringify(request),
        { headers: this.getHeaders() }
      )
      .toPromise();
  }

  uploadQuestionario(
    questionarioFormandoId: number,
    pedidoFormandoId: number,
    file: File
  ): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);

    const headers = new HttpHeaders({
      Authorization: "Bearer " + (this.token ? this.token.access_token : ""),
    });

    return this.http.post(
      `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/${questionarioFormandoId}/upload/${pedidoFormandoId}`,
      formData,
      { headers, responseType: "text" }
    );
  }

  uploadCarta(questionarioFormandoId: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);

    const headers = new HttpHeaders({
      Authorization: "Bearer " + (this.token ? this.token.access_token : ""),
    });

    return this.http.post(
      `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/${questionarioFormandoId}/upload/carta`,
      formData,
      { headers, responseType: "text" }
    );
  }

  removerArquivoQuestionario(questionarioFormandoId: number): Observable<any> {
    return this.http.delete(
      `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/removerarquivo/questionario/${questionarioFormandoId}`,
      { headers: this.getHeaders(), responseType: "text" }
    );
  }

  removerArquivoCarta(questionarioFormandoId: number): Observable<any> {
    return this.http.delete(
      `${environment.moduloFormacao.urlModuloFormacao}questionarioFormando/removerarquivo/carta/${questionarioFormandoId}`,
      { headers: this.getHeaders(), responseType: "text" }
    );
  }
}

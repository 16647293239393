export class MembroComunidade {

    constructor(
        public id: number,
        public tipo: number, //1 - Casa Comunitária | 2 - Celula
        public casaCelulaId: number,
        public pessoaId: number,
        public pessoaNome: string,
        public pessoaImagemId: number,
        public thumbnail: string,
        public dataEntrada: Date,
        public dataSaida: Date,
        public motivoSaidaId: number,
        public obsSaida: string,
        public isFC: number,
        public isFA: number,                
        public nivelFormativoId: number,        
        public formaVidaValor: number,   
        public feedback: string,     
        public editando: boolean
    )
    {}

}
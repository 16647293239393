<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fa fa-list"></i>&nbsp;&nbsp;&nbsp;Discernimentos</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <!-- <div class="row">
                    <div class="col-12">
                        Não há nenhum questionário disponível para informar parecer.
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12" style='text-align: center'>
                                    <table-component [cardBodyClass]="'table-responsive p-0'"
                                        [tableClass]="'table-striped'"
                                        [tituloSessao]="'Questionários disponíveis para informar parecer'" [cols]="8"
                                        [rows]="buscaMembros.quantityOfElements"
                                        [totalDePaginas]="totalDePaginasMembros"
                                        (eventoBotao)="cliqueNoBotaoListener($event)">
                                        <tr tableheader>
                                            <th style="min-width: 10rem;">Foto de perfil</th>
                                            <th style="min-width: 10rem;">Nome</th>
                                            <th>Celula</th>
                                            <th style="min-width: 10rem;">Nível Formativo</th>
                                            <th>Questionário</th>
                                            <th *ngIf="!formadorAssistente" style="min-width: 15rem;">Questionário -
                                                Formando</th>
                                            <th *ngIf="!formadorAssistente" style="min-width: 15rem;">Carta - Formando
                                            </th>
                                            <th>Parecer
                                            </th>
                                            <!--th *ngIf="questionarioConfiguracao?.status == 'DIVULGADO'">Feedback</th-->
                                        </tr>
                                        <tr tablebody *ngFor="let membro of membrosCelula">
                                            <td>
                                                <div class="widget-user-image">
                                                    <img class="profile-user-img img-fluid img-circle image-size-list elevation-2"
                                                        [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail"
                                                        alt="User Avatar">
                                                </div>
                                            </td>
                                            <td>{{membro.pessoaNome}}</td>
                                            <td>{{membro.celulaNome}}</td>
                                            <td>{{membro.nivelFormativoNome}}</td>
                                            <td>
                                                <select *ngIf="!membro.questionarioFormandoId" class="form-control"
                                                    [(ngModel)]="membro.questionarioId" id="questionarioId"
                                                    name="questionarioId" [disabled]="membro.questionarioFormandoId">
                                                    <option disabled [value]="null" selected i18n>Selecione</option>
                                                    <option *ngIf="membro.nivelFormativoId == 25" [value]="4" i18n>
                                                        Pedido para as Primeiras Promessas - CA</option>
                                                    <option
                                                        *ngIf="membro.nivelFormativoId == 30 || membro.nivelFormativoId == 31 || 
                                                    membro.nivelFormativoId == 108 || membro.nivelFormativoId == 204 || membro.nivelFormativoId == 205"
                                                        [value]="5" i18n>Pedido para as Promessas Definitivas - CA
                                                    </option>
                                                    <option
                                                        *ngIf="membro.nivelFormativoId == 22 || membro.nivelFormativoId == 23"
                                                        [value]="6" i18n>Postulantes/Ingresso no Discipulado - CA
                                                    </option>
                                                    <option *ngIf="membro.nivelFormativoId == 24" [value]="7" i18n>
                                                        Discipulos de Primeiro Ano - CA</option>
                                                    <option *ngIf="membro.nivelFormativoId == 26 || 
                                                    membro.nivelFormativoId == 27 || membro.nivelFormativoId == 28 || membro.nivelFormativoId == 29 || membro.nivelFormativoId == 30 || membro.nivelFormativoId == 31 || 
                                                    membro.nivelFormativoId == 108 || membro.nivelFormativoId == 204"
                                                        [value]="8" i18n>
                                                        Renovação das Promessas - CA</option>
                                                </select>
                                                <p *ngIf="membro.questionarioFormandoId">{{membro.questionarioNome}}</p>
                                            </td>
                                            <!--td *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'"-->
                                            <td *ngIf="!formadorAssistente">
                                                <ng-container
                                                    *ngIf="membro.statusQuestionario == 'ENVIADO'; else statusQuestionarioDiferenteEnviado">
                                                    <button class="btn btn-primary"
                                                        (click)="carregarArquivoPDF(membro.arquivo)" data-toggle="modal"
                                                        data-target="#modal-view-questionario" title="Questionario CAL"
                                                        alt="Questionario CAL">Visualizar</button>
                                                    <button class="btn btn-small btn-danger ml-2"
                                                        title="Apagar Questionário"
                                                        (click)="removerArquivoQuestionario(membro.questionarioFormandoId)"
                                                        [disabled]="bloquearRemocaoDeArquivos(membro)"><i
                                                            class="fas fa-trash-alt"></i></button>
                                                    <p *ngIf="bloquearRemocaoDeArquivos(membro)">
                                                        <small>Não é possível apagar o questionário pois uma
                                                            <b>Autoridade</b> já deu um parecer.</small>
                                                    </p>
                                                </ng-container>
                                                <ng-template #statusQuestionarioDiferenteEnviado>
                                                    <button class="btn btn-primary"
                                                        [disabled]="!membro.thumbnail || !membro.questionarioId"
                                                        (click)="configModalUploadQuestionario(membro)"
                                                        data-toggle="modal" data-target="#modal-upload-questionario">
                                                        Enviar Questionário do Formando
                                                    </button>
                                                </ng-template>
                                                <p *ngIf="membro.thumbnail == null">Campo foto é obrigatório</p>
                                                <p *ngIf="!membro.questionarioId">Selecione o questionário</p>
                                            </td>
                                            <td *ngIf="!formadorAssistente">
                                                <ng-container *ngIf="!membro.carta ; else jaEnviouCarta">
                                                    <button type="button" class="btn btn-info"
                                                        [disabled]="!membro.thumbnail || !membro.questionarioId"
                                                        (click)="configModalUploadCarta(membro)" data-toggle="modal"
                                                        data-target="#modal-upload-carta">
                                                        Enviar Carta do Formando
                                                    </button>
                                                    <p *ngIf="membro.thumbnail == null">Campo foto é obrigatório</p>
                                                </ng-container>
                                                <ng-template #jaEnviouCarta>
                                                    <button type="button" class="btn btn-info"
                                                        (click)="carregarCartaPDF(membro.carta)" data-toggle="modal"
                                                        data-target="#modal-view-carta">
                                                        Visualizar Carta
                                                    </button>
                                                    <button class="btn btn-small btn-danger ml-2" title="Apagar Carta"
                                                        (click)="removerArquivoCarta(membro.questionarioFormandoId)"
                                                        [disabled]="bloquearRemocaoDeArquivos(membro)"><i
                                                            class="fas fa-trash-alt"></i></button>
                                                    <p *ngIf="bloquearRemocaoDeArquivos(membro)">
                                                        <small>Não é possível apagar a carta pois uma <b>Autoridade</b>
                                                            já
                                                            deu um parecer.</small>
                                                    </p>
                                                </ng-template>
                                            </td>
                                            <!--td *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'"-->
                                            <td>
                                                <button
                                                    *ngIf="membro.statusParecerFC == 'ENVIADO' && !membro.isFC; else blocoElseIf"
                                                    class="btn btn-primary" disabled title="Parecer CAL"
                                                    alt="Parecer CAL">{{membro.statusParecerFC}}</button>
                                                <ng-template #blocoElseIf>
                                                    <button
                                                        *ngIf="membro.statusParecerFC != 'ENVIADO' && !membro.isFC; else mensagemParecerOutraAutoridade"
                                                        [disabled]="!membro.questionarioId" class="btn btn-primary"
                                                        (click)="consultarVinculoQuestionarioCAL(membro)"
                                                        title="Parecer CAL" alt="Parecer CAL">Responder</button>
                                                    <p *ngIf="!membro.questionarioId">Selecione o questionário</p>
                                                </ng-template>
                                                <ng-template #mensagemParecerOutraAutoridade>
                                                    Acompanhado(a) por outra autoridade
                                                </ng-template>
                                            </td>
                                            <!--td *ngIf="questionarioConfiguracao?.status == 'DIVULGADO'">
                                                {{membro.feedbackCaminhoFormativo ? membro.feedbackCaminhoFormativo
                                                :
                                                'Feedback não informado'}}
                                            </td-->
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="modal-upload-questionario" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Enviar Questionário do Formando</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="card card-secondary">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <label for="fileType">Formando:
                                                    &nbsp;&nbsp;{{questionarioFormandoSelectedNome}}</label><br>
                                                <label for="fileType">Qual pedido o seu formando está
                                                    fazendo?</label><br>
                                                <select class="form-control" [(ngModel)]="pedidoFormandoId"
                                                    name="pedidoFormandoId" (change)="onTipoPedidoChange()">
                                                    <option disabled value="null" i18n>Selecione</option>
                                                    <option value="1"
                                                        *ngIf="questionarioSelectedId == 6 || questionarioSelectedId == 7 || questionarioSelectedId == 8"
                                                        i18n>Mudança de Nível</option>
                                                    <option value="2"
                                                        *ngIf="questionarioSelectedId == 4 || questionarioSelectedId == 6 || questionarioSelectedId == 7"
                                                        i18n>Permanecer no Nível</option>
                                                    <option value="3" *ngIf="questionarioSelectedId == 4" i18n>Realizar
                                                        Primeiras Promessas</option>
                                                    <option value="4" *ngIf="questionarioSelectedId == 5" i18n>Realizar
                                                        Promessas Definitivas</option>
                                                    <option value="5" i18n>Desligamento CAL</option>
                                                    <option value="6" i18n>Outro</option>
                                                </select>

                                                <br>
                                                <input type="file" accept=".pdf" (change)="onFileSelected($event)" />
                                                <div *ngIf="errorMessage" class="error-message">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer text-right">
                                        <button type="button" class="btn btn-primary" (click)="onUpload()"
                                            [disabled]="!selectedFileArquivo">Enviar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-right">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal-view-questionario" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Visualização do questionário</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <iframe *ngIf="sanitizedPdfUrl" [src]="sanitizedPdfUrl" width="100%" height="600px"
                        (contextmenu)="false;"></iframe>
                    <!-- <object [data]="sanitizedPdfUrl" type="application/pdf" width="100%" height="500px">
                        <p>Seu navegador não suporta exibição de PDFs.</p>
                    </object> -->
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal-upload-carta" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Enviar Carta do Formando</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="card card-secondary">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label for="fileType">Formando:
                                                &nbsp;&nbsp;{{questionarioFormandoSelectedNome}}</label><br>
                                            <input type="file" accept=".pdf" (change)="onCartaFileSelected($event)" />
                                            <div *ngIf="errorMessage" class="error-message">
                                                {{ errorMessage }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-right">
                                    <button type="button" class="btn btn-primary" (click)="onCartaUpload()"
                                        [disabled]="!selectedFileCarta">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-right">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal-view-carta" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Visualização da carta</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <iframe *ngIf="sanitizedPdfCartaUrl" [src]="sanitizedPdfCartaUrl" width="100%" height="600px"
                        (contextmenu)="false;"></iframe>
                    <!-- <object [data]="sanitizedPdfUrl" type="application/pdf" width="100%" height="500px">
                        <p>Seu navegador não suporta exibição de PDFs.</p>
                    </object> -->

                </div>
            </div>
        </div>
    </div>
</secured>